.upload {
    &__dropzone {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border: 2px dashed $color__black--10;
        border-radius: 10px;
        padding: 1rem;
        margin-bottom: 1rem;

        &__text {
            text-align: center;
            @include text--muted;
        }
    }
}